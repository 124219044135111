


































/* eslint-disable lines-between-class-members */
/* eslint-disable class-methods-use-this */

import { UserDetails } from '@/types';
import {
  Vue, Component, Prop,
} from 'vue-property-decorator';

@Component
export default class UserCredits extends Vue {
  @Prop(Object) readonly user!: UserDetails

  mounted() {
    console.log('UserCredits: mounted()'); // eslint-disable-line no-console
  }

  get hivesCredits() {
    return this.user?.hivesCredits ?? 0;
  }

  get honeycombsCredits() {
    return this.user?.honeycombsCredits ?? 0;
  }
}
